
// const objectsEqual = (o1, o2) =>
//  Object.keys(o1).length === Object.keys(o2).length &&
//  Object.keys(o1).every((p) => o1[p] === o2[p])

// const arraysEqual = (a1, a2) =>
//   a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))

export default {
  name: 'AtomSelect',
  props: {
    hasIcon: {
      required: false,
      type: Boolean,
      default: false
    },
    id: {
      required: false,
      type: String,
      default: ''
    },
    options: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    selectProps: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false
    },
    searchable: {
      required: false,
      type: Boolean,
      default: false
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    instance: {
      required: false,
      type: String,
      default: ''
    },
    optionLabel: {
      required: false,
      type: String,
      default: 'label'
    },
    hasLabel: {
      required: false,
      type: Boolean,
      default: false
    },
    labelText: {
      required: false,
      type: String,
      default: 'Label'
    },
    value: {
      required: false,
      type: [Number, String, Array, Object],
      default: null
    },
    clearable: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    // inputVal: {
    //   get() {
    //     return this.value
    //   },
    //   set(newVal) {
    //     let val
    //     if (this.multiple) {
    //       val = [...this.value, newVal]
    //     } else {
    //       // select2 returns val as string
    //       // return as int if the string is a valid number
    //       if (isNaN(parseInt(newVal))) {
    //         val = newVal
    //       } else {
    //         val = parseInt(newVal)
    //       }
    //     }
    //
    //     this.$emit('change', val)
    //   }
    // }
  },
  watch: {
    // inputVal(value) {
    //   // $(this.$refs.selectTarget).val(value).trigger('change')
    // },
    options(newValue, oldValue) {
      // check if arrays are equal so we can pass options prop inline
      // otherwise we would have to pass as reactive variable
      // if(arraysEqual(newValue,oldValue)) {
      //   return;
      // }
      // const elem = $(this.$refs.selectTarget)
      //   .empty() // empty because select2 just appends new data
      //   .select2(
      //     {
      //       data: newValue,
      //       ...this.selectProps
      //     },
      //   )
      //   .val(this.inputVal);
      // this.$nextTick(() => {
      //   elem.trigger("change")
      // })
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   elem.trigger("change")
    // });
  },
  beforeDestroy() {
    // $(this.$refs.selectTarget).off().select2("destroy");
  },
  methods: {
    emitEvent(value) {
      this.$emit('change', value)
    }
  }
}
