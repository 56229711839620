
export default {
  name: 'AtomToggle',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toggleValue: this.value
    }
  },
  computed: {
    isToggled: {
      get: function () {
        return this.toggleValue
      },
      set: function (value) {
        // if (typeof value === 'undefined') return;
        this.toggleValue = value
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value: function (value) {
      this.isToggled = value
    }
  },
  methods: {
    toggle() {
      this.isToggled = !this.isToggled
      this.$emit('toggle', this.isToggled)
    }
  }
}
