
export default {
  name: 'AtomLinesBullets',
  props: {
    lines: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  }
}
