
export default {
  name: 'AtomTitle',
  props: {
    title: {
      required: false,
      type: String,
      default: ''
    },
    subtitle: {
      required: false,
      type: String,
      default: ''
    },
    color: {
      required: false,
      type: String,
      default: ''
    },
    color2: {
      required: false,
      type: String,
      default: ''
    },
    disableColorProps: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
