
export default {
  name: 'AtomEmptyPlaceholder',
  props: {
    type: {
      required: true,
      type: String,
      default: ''
    },
    showTitle: {
      required: false,
      type: Boolean,
      default: true
    },
    placeholderText: {
      required: false,
      type: String,
      default: ''
    }
  },
  methods: {
    cdnImage(image) {
      return this.$store.getters['base/cdnBase'] + image
    }
  }
}
