
export default {
  name: 'AtomCheck',
  props: {
    id: {
      required: false,
      type: String,
      default: ''
    },
    label: {
      required: false,
      type: String,
      default: ''
    },
    name: {
      required: false,
      type: String,
      default: 'checkbox'
    },
    value: {
      required: false,
      type: Boolean,
      default: false
    },
    size: {
      required: false,
      type: Number,
      default: 22
    },
    error: {
      required: false,
      type: String,
      default: ''
    },
    isChecked: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: this.isChecked
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.checked
      },
      set(val) {
        this.checked = val
      }
    }
  },
  watch: {
    inputVal: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    isChecked: function (newValue, oldValue) {
      if (typeof newValue !== 'undefined') this.inputVal = newValue
    }
  },
  beforeMount() {
    if (typeof this.isChecked !== 'undefined') this.inputVal = this.isChecked
  },
  methods: {
    handleClick() {
      this.inputVal = !this.inputVal
    },
    setStatus(status) {
      this.inputVal = status
    }
  }
}
