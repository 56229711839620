import { render, staticRenderFns } from "./AtomVerticalLine.vue?vue&type=template&id=486cb736&scoped=true&"
import script from "./AtomVerticalLine.vue?vue&type=script&lang=js&"
export * from "./AtomVerticalLine.vue?vue&type=script&lang=js&"
import style0 from "./AtomVerticalLine.vue?vue&type=style&index=0&id=486cb736&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486cb736",
  null
  
)

export default component.exports