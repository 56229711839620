
export default {
  name: 'AtomStatus',
  props: {},
  data() {
    return {}
  },
  computed: {
    storeFilters() {
      return this.$store.state.availability.filters
    },
    includeLeasedSpacesInResultsList() {
      return this.$store.state.availability.includeLeasedSpaces
    },
    selectedValue() {
      if (
        typeof this.storeFilters.filters.available !== 'undefined' &&
        !this.storeFilters.filters.available &&
        !this.includeLeasedSpacesInResultsList
      ) {
        return 'leased'
      }
      if (this.includeLeasedSpacesInResultsList) {
        return 'all'
      }
      return 'available'
    }
  },
  methods: {
    handleSelect(value) {
      this.$emit('select', value)
    }
  }
}
