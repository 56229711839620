
import AtomButton from '~/components/atoms/common/AtomButton.vue'

const itemKey = 'hasBeenForcedToRefresh'
const itemTimestampKey = 'hasBeenForcedToRefreshAt'

export default {
  name: 'AtomLoadingError',
  components: { AtomButton },
  data() {
    return {
      hasBeenForcedToRefresh: false
    }
  },
  computed: {
    assetErrorText() {
      return this.$store.state.building.assetLoadingError
    },
    displayText() {
      switch (this.assetErrorText) {
        case 'WEBGL_CONTEXT_LOST':
          if (this.hasBeenForcedToRefresh) {
            return this.$t('contextLostErrorMessage')
          }
          return this.$t('contextLostAutoRefreshErrorMessage')
        default:
          return this.$t('errorMessage')
      }
    }
  },
  mounted() {
    switch (this.assetErrorText) {
      case 'WEBGL_CONTEXT_LOST':
        this.handleLocalStorage().then((hasBeenForcedToRefresh) => {
          !hasBeenForcedToRefresh && this.handlePageReload()
        })
        break
    }
  },
  methods: {
    handlePageReload() {
      if (!window) return
      window.location.reload()
    },
    handleLocalStorage() {
      return new Promise((resolve) => {
        if (!window) {
          resolve(true)
          return
        }
        const triggerAutoRefresh = () => {
          localStorage.setItem(itemKey, 'true')
          localStorage.setItem(itemTimestampKey, new Date().getTime())
          this.hasBeenForcedToRefresh = false
          resolve(false)
        }
        const triggerShowButton = () => {
          localStorage.removeItem(itemKey)
          localStorage.removeItem(itemTimestampKey)
          this.hasBeenForcedToRefresh = true
          resolve(true)
        }
        const itemValue = localStorage.getItem(itemKey)
        const itemTimestampValue = localStorage.getItem(itemTimestampKey)
        if (itemValue) {
          const now = new Date().getTime()
          // Calculate the difference in milliseconds
          const diffInMinutes = Math.abs(now - parseInt(itemTimestampValue))
          // Convert the difference to minutes
          const minutesInMillis = 60 * 1000
          if (diffInMinutes > 30 * minutesInMillis) {
            triggerAutoRefresh()
            return
          }
          triggerShowButton()
          return
        }
        triggerAutoRefresh()
      })
    }
  }
}
