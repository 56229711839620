
export default {
  name: 'AtomButton',
  props: {
    label: {
      required: false,
      type: String,
      default: 'Submit'
    },
    icon: {
      required: false,
      type: String,
      default: ''
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    onClick: {
      required: false,
      type: Function,
      default: () => {
        // console.log('default action')
      }
    },
    activateHoverEvent: {
      required: false,
      type: Boolean,
      default: false
    },
    hoverParentType: {
      required: false,
      type: String,
      default: ''
    },
    customLabel: {
      required: false,
      type: String,
      default: 'Submit'
    }
  },
  data() {
    return {
      hover: false
    }
  },
  computed: {
    computedLabel() {
      if (this.hover && this.customLabel) {
        return this.customLabel
      }
      return this.label
    }
  },
  methods: {
    handleHover(value) {
      if (!this.activateHoverEvent) return
      this.hover = value
    }
  }
}
