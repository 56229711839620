import { render, staticRenderFns } from "./AtomLoader.vue?vue&type=template&id=780ba681&scoped=true&"
import script from "./AtomLoader.vue?vue&type=script&lang=js&"
export * from "./AtomLoader.vue?vue&type=script&lang=js&"
import style0 from "./AtomLoader.vue?vue&type=style&index=0&id=780ba681&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "780ba681",
  null
  
)

export default component.exports