
export default {
  name: 'AtomIconAndText',
  props: {
    borderColor: {
      required: false,
      type: String,
      default: '#000'
    },
    icon: {
      required: false,
      type: String,
      default: 'eye'
    },
    iconImage: {
      required: false,
      type: String,
      default: ''
    },
    title: {
      required: false,
      type: String,
      default: ''
    },
    description: {
      required: false,
      type: String,
      default: ''
    }
  },
  computed: {
    hasTitle() {
      return this.title && this.title !== ''
    },
    hasDescription() {
      return this.description && this.description !== ''
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters['base/cdnBase']}/${url}`
    }
  }
}
