
export default {
  name: 'AtomImageTitle',
  props: {
    image: {
      required: true,
      type: String,
      default: ''
    }
  }
}
