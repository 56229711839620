
export default {
  name: 'AtomSpecsInfo',
  components: {},
  props: {
    id: {
      required: false,
      type: String,
      default: ''
    },
    text: {
      required: false,
      type: [String, Object],
      default: ''
    },
    infoId: {
      required: true,
      type: String,
      default: ''
    },
    activeInfo: {
      required: true,
      type: String,
      default: 'item-0'
    }
  },
  computed: {
    isActive() {
      return this.$props.activeInfo === this.infoId
    }
  },
  methods: {
    handleClick() {
      this.$emit('change', this.infoId)
    }
  }
}
