
export default {
  name: 'AtomLoadingError',
  components: {},
  data() {
    return {
      hasBeenForcedToRefresh: false
    }
  },
  computed: {
    contextLost() {
      return this.$store.state.building.webGLContextLost
    }
  },
  mounted() {}
}
