
export default {
  name: 'AtomFilterButton',
  props: {
    href: {
      required: false,
      type: String,
      default: 'javascript:void(0)'
    },
    label: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: false,
      selected: false,
      icon: 'plus-simple'
    }
  },
  methods: {
    changeIcon(e) {
      this.selected = !this.selected
      this.isActive = !this.isActive
      if (this.selected) {
        this.icon = 'minus-simple'
      } else {
        this.icon = 'plus-simple'
      }
      this.$emit('change', this.selected)
    }
  }
}
