
export default {
  name: 'AtomContentText',
  props: {
    text: {
      required: false,
      type: String,
      default: ''
    },
    color: {
      required: true,
      type: String,
      default: '#fff'
    }
  }
}
