
export default {
  name: 'AtomFacts',
  props: {
    facts: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    columns: {
      required: false,
      type: String,
      default: 'col-xs-12 col-4'
    }
  }
}
