
export default {
  name: 'AtomMenuItem',
  props: {
    label: {
      required: false,
      type: String,
      default: 'Menu Item'
    },
    icon: {
      required: false,
      type: String,
      default: ''
    },
    tabId: {
      required: false,
      type: String,
      default: ''
    },
    to: {
      required: false,
      type: Object,
      default: null
    },
    onClick: {
      required: false,
      type: Function,
      default: function () {}
    }
  }
}
