
import 'v-tooltip'
import projectConstants from '~/store/project/-constants'

export const EnumInputTypes = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  DATE: 'date',
  NUMBER: 'number',
  PASSWORD: 'password',
  TEL: 'tel'
}

export default {
  name: 'AtomDropDownFilter',
  props: {
    options: {
      required: true,
      type: Array,
      default: () => []
    },
    value: {
      required: false,
      type: Array,
      default: () => []
    },
    placeholder: {
      required: false,
      type: String,
      default: 'Filter'
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    clearFloorFilters(event) {
      event.stopPropagation()
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.RESET_FLOOR_FILTER)
      )
    },
    handleWidth() {
      const input = this.$refs['input-show']
      const wrapper = this.$refs['popover-wrapper']
      const { width } = input.getBoundingClientRect()
      wrapper.style.width = `${width}px`
    },
    handleInput(id, val) {
      let values = [...this.inputVal]
      if (val) {
        values.push(id)
      } else {
        values = values.filter((v) => v !== id)
      }
      this.inputVal = values
    }
  }
}
