
export default {
  name: 'AtomVerticalLine',
  props: {
    color: {
      required: false,
      type: String,
      default: ''
    }
  }
}
