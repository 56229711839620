
export default {
  name: 'AtomCircleProgress',
  props: {
    progress: {
      type: Number,
      required: true,
      default: 0
    },
    size: {
      type: String,
      required: false,
      default: '2.5rem'
    },
    fill: {
      type: Object,
      required: false,
      default: function () {
        return { gradient: ['black'] }
      }
    }
  },
  data() {
    return {}
  },
  watch: {
    progress: function (newProgress) {
      this.$refs.progress?.updateProgress(newProgress)
    }
  },
  methods: {
    onProgress(event, progress, stepValue) {
      this.$emit('progress', event, stepValue, progress)
    },
    onProgressEnd(event) {
      this.$emit('progress-end', event)
    }
  }
}
