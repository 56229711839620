
export default {
  name: 'AtomSimpleButton',
  props: {
    label: {
      required: false,
      type: String,
      default: ''
    },
    justIcon: {
      required: false,
      type: Boolean,
      default: false
    },
    iconLeft: {
      required: false,
      type: String,
      default: ''
    },
    iconRight: {
      required: false,
      type: String,
      default: ''
    },
    submitButton: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e)
    }
  }
}
