
export default {
  name: 'AtomInfoPopup',
  props: {
    text: {
      required: true,
      type: String,
      default: ''
    }
  }
}
